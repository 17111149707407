import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <Container
      maxWidth={false}
      sx={{ background: "#000", p: "48px 0 24px", position: "relative" }}
    >
      <Box textAlign="center">
        <Typography className="MM" sx={{ fontSize: "16px", color: "#fff" }}>
          MISS UNIVERSE VIETNAM 2024
        </Typography>
        <Typography
          className="LCS"
          sx={{
            fontSize: "48px",
            color: "#fff",
            m: "12px 0 36px",
            "@media (max-width: 600px)": {
              fontSize: "36px",
            },
          }}
        >
          Nguyễn Cao Kỳ Duyên
        </Typography>
        <Typography className="MM" fontSize="12px" sx={{ color: "#fff" }}>
          © 2024 Dược sĩ Tiến Entertainment
        </Typography>
      </Box>
    </Container>
  );
}
