import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function TotalSign(lang) {
  const matches = useMediaQuery("(max-width:600px)");
  const [total, setTotal] = useState(0);
  const getTotalSign = async () => {
    await fetch(`${process.env.REACT_APP_PUBLISH_URL}/supporter/count`)
      .then((response) => response.json())
      .then((data) => setTotal(data?.data))
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    setInterval(() => {
      getTotalSign();
    }, 3000); // Call every 3 seconds
  }, []);
  return (
    <Container
      maxWidth={false}
      sx={{ position: "relative", background: "#fff" }}
    >
      <Grid
        xs={12}
        item
        sx={{ display: "flex", justifyContent: "center", pb: "60px" }}
      >
        <Box
          sx={{
            maxWidth: "610px",
            width: "100%",
            position: "relative",
            height: "360px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 425px)": {
              height: "275px",
            },
          }}
        >
          <img
            style={{
              height: !matches ? "100%" : "auto",
              width: matches ? "100%" : "auto",
              position: "absolute",
            }}
            src="/img/total_sign.png"
            alt=""
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 10,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {!lang?.lang ? (
              <Typography
                sx={{
                  fontSize: "20px",
                  "@media (max-width: 425px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Thật vui vì Duyên đã <br />
                kết nối được với
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "20px",
                  "@media (max-width: 425px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Duyên is utterly grateful <br /> to establish connections with
              </Typography>
            )}
            <Typography
              className="MB"
              sx={{
                fontSize: "72px",
                lineHeight: "78px",
                "@media (max-width: 425px)": {
                  fontSize: "48px",
                  lineHeight: "56px",
                },
              }}
            >
              {total}
            </Typography>
            <img
              className="heart"
              style={{ width: "64px" }}
              src="/img/total_sign_heart.png"
              alt=""
            />
            <Typography
              sx={{
                fontSize: "20px",
                mt: "6px",
                "@media (max-width: 425px)": {
                  fontSize: "14px",
                },
              }}
            >
              {!lang?.lang ? "tình yêu thương." : "lovely friends."}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
