import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SignaturePad from "signature_pad";

const SignaturePadComponent = ({
  setSupporter,
  canvasRef,
  signaturePadRef,
  handleClear,
  title,
  description
}) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    const signaturePad = new SignaturePad(canvas);
    signaturePadRef.current = signaturePad;

    // Adjust canvas size
    resizeCanvas();
  }, []);

  // Resize canvas to fit parent container
  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  };

  // Clear signature

  return (
    <div>
      <Typography mb="8px" fontWeight="bold">
        {title} *
      </Typography>
      <Typography mb="8px">{description}</Typography>
      <div
        style={{
          border: "1px solid #c4c4c4",
          width: "100%",
          height: "250px",
          margin: "auto",
          borderRadius: "4px",
        }}
      >
        <canvas ref={canvasRef} style={{ width: "100%", height: "100%", cursor: "pointer" }} title="Sign here" />
      </div>
      <Button
        className="MM"
        onClick={handleClear}
        sx={{
          border: "1px solid #c4c4c4",
          borderRadius: "12px",
          color: "#000",
          padding: "6px 36px",
          m: "12px auto 0 0",
        }}
      >
        Clear
      </Button>
    </div>
  );
};

export default SignaturePadComponent;
