export const generateBookImg = (lang) => {
 const page = Array.from({ length: 46 }, (_, i) => {
  return `https://files.muv2024.vn/kyduyenbio/${
    !lang ? "vi" : "en"
  }/${i + 1}.jpg`;
})
  return page;
};

// https://files.muv2024.vn/kyduyenbio/

export const headerImgs = [
  "MINN9518.jpg",
  "MINN9535.jpg",
  "DSC_5945.jpg",
  "DSC_4443.jpg",
  "2C2A6704.jpg",
  "461426366_1129552851872844_4405780281242101209_n.jpg",
  "MUV6469.jpg",
  "MUV6564.jpg",
  "2C2A6524.jpg",
  "MUV-4632.jpg",
  "2C2A7268.jpg",
  "DSC_3533.jpg",
  "DSC_3203.jpg",
  "461491264_1129552665206196_4832626375724957013_n.jpg",
  "DSC_6240.jpg",
  "DSC_3561.jpg",
  "461752865_1130357271792402_4686816513966955402_n.jpg",
  "DSC_4364.jpg",
  "MUV5403.jpg",
  "DSC_4868.jpg",
  "DSC_6161_1.jpg"
];
