import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { headerImgs } from "../mocks/book-list";
const uppercase = { textTransform: "uppercase" };
export default function Header({ handleChangeLang, lang }) {
  const matches = useMediaQuery("(max-width:899px)");

  const settings = {
    vertical: matches ? false : true,
    verticalSwiping: matches ? false : true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3800,
    autoplaySpeed: 3800,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <Grid
      container
      sx={{
        p: "0 48px !important",
        "@media (max-width: 899px)": {
          p: "0!important",
        },
      }}
    >
      {/* LANGUAGE */}
      <Grid
        xs={12}
        item
        textAlign="right"
        mb="24px"
        sx={{ zIndex: 15, position: "absolute" }}
      >
        <label className="language-switcher">
          <input
            checked={lang}
            value={lang}
            onChange={handleChangeLang}
            type="checkbox"
            id="language-toggle"
          />
          <span className="slider round"></span>
          <span className="select-fr MM">VI</span>
          <span className="select-en MM">EN</span>
        </label>
      </Grid>
      {/* INTRO + IMG */}
      <Grid
        container
        sx={{
          "@media (max-width: 899px)": { height: "100%" },
        }}
      >
        {/* MOBILE */}
        <Grid
          xs={12}
          item
          sx={{
            display: matches ? "block" : "none",
            "@media (max-width 600px)": {
              height: "200px !important",
              overflow: "hidden",
            },
          }}
        >
          <Slider {...settings}>
            {headerImgs?.slice(0, 6).map((v) => {
              return (
                <Box
                  key={v}
                  flex={1}
                  sx={{
                    background: `url(https://files.muv2024.vn/headers/${v}) center center / cover`,
                    height: 0,
                    paddingBottom: "100%",
                    width: !matches ? "100%" : "33.33%",
                    display: "flex",
                    alignItems: "flex-end",
                    transition: "transform 0.5s ease",
                    position: "relative",
                    "@media (max-width: 899px)": {
                      pb: "90%",
                    },
                    "@media (max-width: 600px)": {
                      pb: "0%",
                      height: "180px",
                    },
                  }}
                />
              );
            })}
          </Slider>
        </Grid>
        {/* INTRO */}
        <Grid
          md={3.5}
          xs={12}
          item
          sx={{
            textAlign: "left",
            mt: "48px",
            "@media (max-width:899px)": {
              position: "relative",
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 0,
            },
          }}
        >
          <Box
            sx={{
              "@media (max-width: 899px)": {
                background: "#fff",
                padding: "36px",
              },
            }}
          >
            <Typography
              className="LCS"
              sx={{
                fontSize: "24px",
                ...uppercase,
                "@media (max-width: 899px)": {
                  fontSize: "16px",
                },
              }}
            >
              miss universe vietnam 2024
            </Typography>
            <Box
              sx={{
                m: "12px 0",
                width: "46px",
                "@media (max-width: 899px)": {
                  width: "36px",
                },
              }}
            >
              <img style={{ width: "100%" }} src="/img/start.jpg" alt="" />
            </Box>
            <Typography
              className="MB"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                ...uppercase,
                "@media (max-width: 899px)": {
                  fontSize: "16px",
                },
              }}
            >
              miss universe vietnam{" "}
            </Typography>
            <Typography
              className="LCS"
              sx={{
                fontSize: "96px",
                lineHeight: "110px",
                m: "36px 0 60px",
                "@media (max-width: 899px)": {
                  fontSize: "72px",
                  lineHeight: "90px",
                  m: "16px 0 22px",
                  "&.MuiTypography-root > br": {
                    display: "none",
                  },
                },
                "@media (max-width: 600px)": {
                  fontSize: "48px",
                  lineHeight: "56px",
                  m: "16px 0 0px",
                },
              }}
            >
              Nguyễn Cao <br /> Kỳ Duyên
            </Typography>
            <Typography
              className="MB"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",

                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              A very normal girl, just <br /> like any other typical <br />{" "}
              Vietnamese girl...
            </Typography>
            <Typography
              className="MM"
              sx={{
                fontSize: "14px",
                lineHeight: "18px",
                margin: "36px 0 52px",
                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              Một cô gái hết sức bình thường và <br /> giống như bao người phụ
              nữ Việt Nam <br />
              điển hình khác
            </Typography>
            <Typography
              className="LCS"
              sx={{
                fontSize: "56px",
                ...uppercase,
                letterSpacing: "-4px",
                width: "78%",
                lineHeight: "72px",
                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              has learned <br /> how to forgive <br /> herself.
            </Typography>
            <Typography
              className="MM"
              sx={{
                fontSize: "28px",
                ...uppercase,
                mt: "24px",
                "@media (max-width: 899px)": {
                  display: "none",
                },
              }}
            >
              đã học được cách tha <br /> thứ cho mình
            </Typography>
          </Box>
        </Grid>
        {/* IMG */}
        <Grid
          md={7}
          xs={12}
          item
          container
          sx={{
            position: "fixed",
            right: 0,
            "@media (max-width: 899px)": {
              height: "65vh",
              display: "none;",
            },
          }}
        >
          <Grid md={4} xs={12} item>
            <Slider {...settings} style={{ display: "flex !important" }}>
              {headerImgs?.slice(0, 6).map((v) => {
                return (
                  <Box
                    flex={1}
                    sx={{
                      background: `url(https://files.muv2024.vn/headers/${v}) center center / cover`,
                      height: 0,
                      paddingBottom: "100%",
                      width: !matches ? "100%" : "33.33%",
                      display: "flex",
                      alignItems: "flex-end",
                      transition: "transform 0.5s ease",
                      position: "relative",
                      "@media (max-width: 899px)": {
                        pb: "90%",
                      },
                      "@media (max-width: 600px)": {
                        pb: "100%",
                      },
                    }}
                  />
                );
              })}
            </Slider>
          </Grid>
          <Grid md={4} xs={12} item sx={{ transform: "scaleY(-1)" }}>
            <Slider {...settings}>
              {headerImgs?.slice(7, 13)?.map((v) => {
                return (
                  <Box
                    key={v}
                    flex={1}
                    sx={{
                      background: `url(https://files.muv2024.vn/headers/${v}) center center / cover`,
                      height: 0,
                      paddingBottom: "100%",
                      width: !matches ? "100%" : "33.33%",
                      display: "flex",
                      alignItems: "flex-end",
                      transition: "transform 0.5s ease",
                      position: "relative",
                      transform: "scaleY(-1)",
                      "@media (max-width: 899px)": {
                        pb: "90%",
                      },
                      "@media (max-width: 600px)": {
                        pb: "100%",
                      },
                    }}
                  />
                );
              })}
            </Slider>
          </Grid>
          <Grid md={4} xs={12} item>
            <Slider {...settings} style={{ display: "flex !important" }}>
              {headerImgs?.slice(14, 20)?.map((v) => {
                return (
                  <Box
                    key={v}
                    flex={1}
                    sx={{
                      background: `url(https://files.muv2024.vn/headers/${v}) center center / cover`,
                      height: 0,
                      paddingBottom: "100%",
                      width: !matches ? "100%" : "33.33%",
                      display: "flex",
                      alignItems: "flex-end",
                      transition: "transform 0.5s ease",
                      position: "relative",
                      "@media (max-width: 899px)": {
                        pb: "90%",
                      },
                      "@media (max-width: 600px)": {
                        pb: "100%",
                      },
                    }}
                  />
                );
              })}
            </Slider>
          </Grid>
        </Grid>

        {/* MOBILE */}
        <Grid
          xs={12}
          item
          sx={{
            display: matches ? "block" : "none",
            transform: "scaleX(-1)",
            height: "180px",
            overflow: "hidden",
          }}
        >
          <Slider {...settings}>
            {headerImgs?.slice(7, 13)?.map((v) => {
              return (
                <Box
                  key={v}
                  flex={1}
                  sx={{
                    background: `url(https://files.muv2024.vn/headers/${v}) center center / cover`,
                    height: 0,
                    paddingBottom: "100%",
                    width: !matches ? "100%" : "33.33%",
                    display: "flex",
                    alignItems: "flex-end",
                    transition: "transform 0.5s ease",
                    position: "relative",
                    "@media (max-width: 899px)": {
                      pb: "90%",
                    },
                    "@media (max-width: 600px)": {
                      pb: "0%",
                      height: "180px",
                    },
                  }}
                />
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </Grid>
  );
}
