import React, { useState } from "react";
import Book from "./Book";
import { Container } from "@mui/material";
import Header from "../Header";
import RegisterForm from "./RegisterForm";
import Footer from "../Footer";
import TotalSign from "./TotalSign";

export default function HomePage() {
  const [lang, setLang] = useState(false);
  const handleChangeLang = () => {
    setLang(!lang);
  };
  return (
    <Container maxWidth={false} sx={{ p: "0 !important" }}>
      {/* HEADER */}
      <Header handleChangeLang={handleChangeLang} lang={lang} />

      {/* BOOK */}
      <Book lang={lang} />

      {/* TOTAL SIGN */}
      <TotalSign lang={lang}/>

      {/* REGISTER */}
      <RegisterForm lang={lang} />

      {/* FOOTER */}
      <Footer />
    </Container>
  );
}
