import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import SignaturePadComponent from "./SignaturePad";
import AlertDialog from "./dialog/AlertDialog";
const descriptionVi =
  "Duyên đã mất rất lâu để học được cách tha thứ cho bản thân mình. Giờ đến lượt bạn, chúng ta cùng bắt đầu với chữ ký này như một lời cam kết nhé!";
const descriptionEn =
  "It took Duyen a long time to learn how to forgive herself. It's your turn now. Let start with this signature as a commitment!";

export default function RegisterForm({ lang }) {
  const token = window.location;
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [successDialog, setSuccessDialog] = useState(false);
  const [supporter, setSupporter] = useState({
    area: "vietnam",
    gender: "female",
    fullName: "", // Giá trị mặc định cho fullName
    groupAge: "", // Giá trị mặc định cho groupAge
    email: "", // Giá trị mặc định cho email
    province: "",
  });
  const [provinces, setProvinces] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [dialogContent, setDialogContent] = useState();

  useEffect(() => {
    if (token?.search?.includes("key")) {
      handleCloseSuccessDialog();
      setDialogContent({
        content: "Đăng ký thành công. Vui lòng chọn xác nhận để hoàn thành!",
        btnTxt: "Xác nhận",
      });
    }
  }, [token?.search]);

  const handleVerifyEmail = async (key) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLISH_URL}/supporter/verify-email${key}`
      );
      // Check if the response is OK (status code in the range 200-299)
      if (res.ok) {
        // Assuming the API returns JSON data, you can parse it
        const data = await res.json();
        console.log(data); // Log the data for debugging purposes

        handleCloseSuccessDialog();

        // Clear URL parameters
        const url = window.location.origin;
        window.history.replaceState(null, "", url); // Update the URL without reloading
      } else {
        // Handle non-OK response
        const errorData = await res.json(); // Assuming the API returns an error message in JSON
        console.error("Error:", errorData);
        // You might want to show an error message to the user here
        alert(`Error: ${errorData.message || "An error occurred."}`);
      }
    } catch (err) {
      console.error("Fetch error:", err);
      // Show a generic error message to the user
      alert("An error occurred while verifying the email.");
    }
  };

  useEffect(() => {
    const fetchProvinces = async () => {
      fetch(`${process.env.REACT_APP_PUBLISH_URL}/province?limit=100&offset=0`)
        .then((response) => response.json())
        .then((data) => setProvinces(data?.data))
        .catch((error) => console.error("Error:", error));
    };
    fetchProvinces();
  }, []);

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required(!lang ? "Vui lòng chọn giới tính" : "Please select gender"),
    fullName: Yup.string().required(!lang ? "Vui lòng nhập Tên của bạn!" : "Please enter your name!"),
    groupAge: Yup.string().required(!lang ? "Vui lòng chọn độ tuổi của bạn!" : "Please select your age group!"),
    email: Yup.string()
      .email(!lang ? "Vui lòng nhập đúng Email của bạn" : "Please enter correct your email")
      .required(!lang ? "Vui lòng nhập Email của bạn" : "Please enter your email"),
    area: Yup.string().required(!lang ? "Vui lòng chọn nơi sinh sống của bạn" : "Please select your place of residence"),
    // Ensure that province is only required if area is defined and equals "vietnam"
    province: Yup.string().when("area", (area, schema) => {
      return area?.includes("vietnam")
        ? schema.required(!lang ? "Vui lòng chọn Tỉnh/ Thành phố" : "Please select Province/City")
        : schema.notRequired();
    }),
    file: Yup.mixed().required(!lang ? "Vui lòng ký tên của bạn" : "Please sign your name"),
  });
  
  

  const handleSave = () => {
    if (!signaturePadRef.current.isEmpty()) {
      const dataURL = signaturePadRef.current.toDataURL("image/png");
      const file = base64ToFile(dataURL, "signature.png");
      if (file) {
        return file;
      }
    }
  };
  // Function to convert base64 to File object
  const base64ToFile = (base64Data, fileName) => {
    const byteString = atob(base64Data.split(",")[1]); // Decode base64 string
    const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0]; // Get MIME type (e.g., image/png)
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    // Return a File object
    return new File([byteArray], fileName, { type: mimeString });
  };

  const handleSendWithFile = async (e) => {
    e.preventDefault();
    try {
      const supportData = {
        gender: supporter.gender,
        fullName: supporter.fullName,
        groupAge: supporter.groupAge,
        email: supporter.email,
        area: supporter?.area,
        province: supporter?.province,
        file: handleSave(),
      };
      // Validate the supporter object using the updated Yup schema
      await validationSchema.validate(supportData, { abortEarly: false });

      const formData = new FormData();
      // Append each field to FormData
      formData.append("gender", supporter.gender);
      formData.append("fullName", supporter.fullName);
      formData.append("groupAge", supporter.groupAge);
      formData.append("email", supporter.email);
      formData.append("message", supporter.message);
      formData.append("file", handleSave());
      formData.append(
        "province",
        supporter?.area === "vietnam" ? supporter?.province : ""
      );

      try {
        const response = await fetch(
          `${process.env.REACT_APP_PUBLISH_URL}/supporter`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
          setDialogContent({
            content: (
              <>
                {!lang ? (
                  <Typography>
                    Thật vui vì Duyên đã được kết nối với bạn! <br /> Vui lòng
                    kiểm tra email để xác nhận.
                  </Typography>
                ) : (
                  <Typography>
                    So happy for me to connect with you! <br /> Please check
                    your email to confirm.
                  </Typography>
                )}
              </>
            ),

            btnTxt: !lang ? "Đóng" : "Close",
          });
          setSupporter({
            area: "vietnam",
            gender: "female",
            fullName: "",
            groupAge: "",
            email: "",
            province: "",
            message: "",
          });
          setFormErrors({}); // Reset form errors
          handleCloseSuccessDialog();
          handleClear();
        }
      } catch (error) {
        console.log("Error in fetch request:", error);
      }
    } catch (validationErrors) {
      if (validationErrors.inner) {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        console.log(errors);
        setFormErrors(errors); // Set errors in the state
      } else {
        console.log("Validation failed:", validationErrors.message);
      }
    }
  };

  const handleClear = () => {
    signaturePadRef?.current?.clear();
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  return (
    <>
      <Grid
        container
        sx={{
          background: "#fff",
          position: "relative",
          zIndex: 10,
          pb: "120px",
        }}
      >
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            m: "0 auto",
            padding: "36px",
            maxWidth: "1000px !important",
            "@media (max-width: 600px)": {
              p: "24px !important",
            },
          }}
        >
          <form onSubmit={handleSendWithFile}>
            <Box
              component="fieldset"
              sx={{
                padding: "36px",
                borderRadius: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                "@media (max-width: 600px)": {
                  p: "18px",
                },
              }}
            >
              {/* TITLE LABEL */}
              <legend
                style={{
                  textAlign: "center",
                  marginBottom: "12px !important",
                }}
              >
                <Typography
                  className="MB"
                  sx={{
                    borderRadius: "100px",
                    fontSize: "32px",
                    color: "#fff",
                    background: "#000",
                    textTransform: "uppercase",
                    display: "inline-table",
                    padding: "6px 36px",
                    "@media (max-width: 600px)": {
                      fontSize: "24px",
                    },
                  }}
                >
                  {!lang ? "Cam kết của Duyên và bạn" : "Commitment between us"}
                </Typography>
              </legend>

              <Typography
                align="center"
                sx={{ fontStyle: "italic", fontSize: "20px" }}
                mb="8px"
              >
                {!lang
                  ? "Để lại chữ ký của bạn để cùng nhau chạm đến mục tiêu"
                  : "Leave your signature to reach target together"}
              </Typography>
              <Typography fontWeight="bold">
                {!lang ? "Tên của bạn" : "Your name"} *
              </Typography>
              <TextField
                sx={{
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `5px dotted red`,
                    },
                  },
                }}
                value={supporter?.fullName}
                name="fullName"
                fullWidth
                label={!lang ? "Họ tên" : "Fullname"}
                onChange={(e) =>
                  setSupporter({ ...supporter, fullName: e.target.value })
                }
              />
              {formErrors.fullName && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.fullName}
                </Typography>
              )}
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {!lang ? "Độ tuổi" : "Age"}
                </InputLabel>
                <Select
                  name="groupAge"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={supporter?.groupAge}
                  label={!lang ? "Độ tuổi" : "Age"}
                  onChange={(e) =>
                    setSupporter({ ...supporter, groupAge: e.target.value })
                  }
                >
                  <MenuItem value={"10-19"}>10 - 19</MenuItem>
                  <MenuItem value={"20-29"}>20 - 29</MenuItem>
                  <MenuItem value={"30-39"}>30 - 39</MenuItem>
                  <MenuItem value={"40-49"}>40 - 49</MenuItem>
                  <MenuItem value={">=50"}>
                    {!lang ? "Lớn hơn 50" : "Over 50"}
                  </MenuItem>
                </Select>
              </FormControl>
              {formErrors.groupAge && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.groupAge}
                </Typography>
              )}

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <Typography
                    mb="8px"
                    fontWeight={700}
                    sx={{ color: "#000 !important" }}
                  >
                    {!lang ? "Nơi sinh sống" : "Place of residence"} *
                  </Typography>
                </FormLabel>
                <RadioGroup
                  name="area"
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={supporter?.area}
                  onChange={(e) =>
                    setSupporter({ ...supporter, area: e.target.value })
                  }
                >
                  <FormControlLabel
                    value="vietnam"
                    control={<Radio />}
                    label="Việt Nam"
                    sx={{
                      fontSize: "12px !important",
                      "&.MuiFormControlLabel-root .Mui-checked": {
                        color: "#000 !important",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="foreign"
                    control={<Radio />}
                    label={!lang ? "Nước Ngoài" : "Foreign"}
                    sx={{
                      fontSize: "12px !important",
                      "&.MuiFormControlLabel-root .Mui-checked": {
                        color: "#000 !important",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
              {formErrors.area && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.area}
                </Typography>
              )}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {!lang ? "Tỉnh/ Thành phố" : "Province/City"}
                </InputLabel>
                <Select
                  disabled={supporter?.area === "foreign"}
                  label={!lang ? "Tỉnh/ Thành phố" : "Province/City"}
                  name="area"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={supporter?.province}
                  onChange={(e) =>
                    setSupporter({ ...supporter, province: e.target.value })
                  }
                >
                  {!!provinces &&
                    provinces?.map((v) => {
                      return (
                        <MenuItem value={v?._id}>{v?.name ?? "-"}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {formErrors.province && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.province}
                </Typography>
              )}

              <TextField
                fullWidth
                label="Email"
                value={supporter?.email}
                sx={{
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `5px dotted red`,
                    },
                  },
                }}
                onChange={(e) =>
                  setSupporter({ ...supporter, email: e.target.value })
                }
              />
              {formErrors.email && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.email}
                </Typography>
              )}

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <Typography
                    mb="8px"
                    fontWeight={700}
                    sx={{ color: "#000 !important" }}
                  >
                    {!lang ? "Giới tính" : "Genders"} *
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={supporter?.gender}
                  name="radio-buttons-group"
                  onChange={(e) =>
                    setSupporter({ ...supporter, gender: e.target.value })
                  }
                >
                  <FormControlLabel
                    sx={{
                      fontSize: "12px !important",
                      "&.MuiFormControlLabel-root .Mui-checked": {
                        color: "#000 !important",
                      },
                    }}
                    value="female"
                    control={<Radio />}
                    label={!lang ? "Nữ" : "Female"}
                  />
                  <FormControlLabel
                    sx={{
                      fontSize: "12px !important",
                      "&.MuiFormControlLabel-root .Mui-checked": {
                        color: "#000 !important",
                      },
                    }}
                    value="male"
                    control={<Radio />}
                    label={!lang ? "Nam" : "Male"}
                  />
                  <FormControlLabel
                    sx={{
                      fontSize: "12px !important",
                      "&.MuiFormControlLabel-root .Mui-checked": {
                        color: "#000 !important",
                      },
                    }}
                    value="other"
                    control={<Radio />}
                    label={!lang ? "Khác" : "Other"}
                  />
                </RadioGroup>
              </FormControl>
              {formErrors.gender && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.gender}
                </Typography>
              )}

              <Box>
                <SignaturePadComponent
                  handleClear={handleClear}
                  setSupporter={setSupporter}
                  canvasRef={canvasRef}
                  signaturePadRef={signaturePadRef}
                  title={!lang ? "Chữ ký" : "Signature"}
                  description={!lang ? descriptionVi : descriptionEn}
                />
              </Box>
              {formErrors.file && (
                <Typography sx={{ fontSize: "12px", color: "#ff4646" }}>
                  {formErrors.file}
                </Typography>
              )}

              <Typography fontWeight="bold">
                {!lang
                  ? "Một điều gì đó bạn muốn nhắn gửi đến Duyên"
                  : "Ready to listen any your words"}
              </Typography>
              <TextField
                multiline
                value={supporter?.message}
                rows={4}
                onChange={(e) =>
                  setSupporter({ ...supporter, message: e.target.value })
                }
              />
              <Button
                type="submit"
                fullWidth
                sx={{
                  background: "#000",
                  color: "#fff",
                  height: "50px",
                  fontSize: "16px",
                }}
                className="MB"
              >
                {!lang ? "Xác nhận" : "Confirm"}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
      <AlertDialog
        open={successDialog}
        handleClose={
          token?.search?.includes("key")
            ? handleVerifyEmail
            : handleCloseSuccessDialog
        }
        content={dialogContent?.content}
        btnTxt={dialogContent?.btnTxt}
      />
    </>
  );
}
