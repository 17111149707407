import "./styles/font/fontStyle.css";
import HomePage from "./components/home/HomePage";

function App() {

  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
