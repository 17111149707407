import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";

export default function AlertDialog({ open, handleClose, content, btnTxt }) {
  const handleClick = () => {
    if (window.location.search?.includes("key")) {
      handleClose(window.location.search);
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          textAlign="center"
          sx={{ fontSize: "24px", color: "#000" }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "0 36px 36px" }}>
        <Button
          fullWidth
          sx={{ background: "#000", color: "#fff", height: "32px" }}
          className="MB"
          onClick={handleClick} 
        >
          {btnTxt}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
